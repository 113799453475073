body {
    margin: 0;
    overflow: hidden;
}
h1 {
    color: hotpink;
}

nav {
    padding: 1em;
    line-height: 1.5em;
}
nav a {
    display: block;
}

#webglContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.noise {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    background-image: url("../dist/assets/images/noise.png");
    opacity: 0.4;
}
body {
}
