body {
  margin: 0;
  overflow: hidden;
}

h1 {
  color: #ff69b4;
}

nav {
  padding: 1em;
  line-height: 1.5em;
}

nav a {
  display: block;
}

#webglContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.noise {
  pointer-events: none;
  opacity: .4;
  background-image: url("noise.9c776b8e.png");
  position: fixed;
  inset: 0;
}

/*# sourceMappingURL=index.2109a41c.css.map */
